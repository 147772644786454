import React, { useState } from "react";
import "./App.css";

function App() {
  const [clouds, setClouds] = useState([]);

  const handleAddCloud = () => {
    const id = Math.random().toString(36).substring(2, 9);
    const leftPosition = Math.random() * 100; // Random horizontal position
    const size = Math.random() * 4 + 2; // Random size (2rem to 6rem)
    setClouds((prevClouds) => [
      ...prevClouds,
      { id, left: `${leftPosition}%`, size },
    ]);
  };

  const handleCloudAnimationEnd = (id) => {
    // Remove cloud after its animation ends
    setClouds((prevClouds) => prevClouds.filter((cloud) => cloud.id !== id));
  };

  return (
    <div
      className="h-screen w-screen flex flex-col justify-center items-center bg-white overflow-hidden relative"
      onClick={handleAddCloud}
      style={{ fontFamily: "'Parkinsans', sans-serif" }} // Apply Parkinsans font
    >
      {/* Main text */}
      <div className="text-center">
        <h1 className="text-5xl md:text-8xl font-black text-black">
          Aerafi
        </h1>
          <div className="flex justify-center gap-4 mt-4">
            {/* Twitter Logo with link */}
            <a href="https://x.com/Aera_Finance" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter fa-lg text-black"></i>
            </a>
          </div>
      </div>

      {/* Clouds */}
      {clouds.map((cloud) => (
        <div
          key={cloud.id}
          className="absolute"
          style={{
            left: cloud.left,
            top: "-50px",
            fontSize: `${cloud.size}rem`, // Scale size dynamically (large clouds)
            animation: "fall 6s linear forwards", // Single falling animation
          }}
          onAnimationEnd={() => handleCloudAnimationEnd(cloud.id)} // Remove cloud after animation ends
        >
          ☁️
        </div>
      ))}

      {/* Custom animation */}
      <style>
        {`
          @keyframes fall {
            to {
              transform: translateY(200vh); /* Continues falling beyond the screen */
            }
          }
        `}
      </style>
    </div>
  );
}

export default App;
